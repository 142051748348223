import http from "http-common";

const TypePanneService = class {
  /**
   * Fonction permettant de récuperer tous les TypePanne
   * @returns la liste de tous les TypePanne
   */
  static getAll(params) {
    let url = "/typespanne";
    
    //Gestion de la valeur par défaut de la propriété "isActive" des paramètres
    if (!params)
      return http.get(url, {params: {isActive: true}});
    else if (typeof params === "object" && params.isActive === undefined)
        return http.get(url, {params: {...params, isActive: true}});
    else if (typeof params === "object" && params.isActive !== undefined)
      return http.get(url, {params});
    else
      return http.get(url, {params: {isActive: true}});
  }

  /**
   * Fonction permettant de récupérer un TypePanne via son id
   * @param {*} id, id d'un TypePanne à récupérer
   * @returns le TypePanne souhaité
   */
  static getById(id) {
    return http.get("/typespanne/" + id);
  }

  /**
   * Fonction permettant de récupérer des TypePanne via l'id d'une famille d'article
   * @param {*} id, id des TypePanne à récupérer
   * @returns les TypePanne souhaités
   */
  static getByIdFamilleAffaire(id) {
    //nom de la fonction à corriger ?
    if (id) {
      return http.get("/typespanne/famillearticle/" + id);
    } else {
      return new Promise(() => {});
    }
  }
};

export { TypePanneService };
