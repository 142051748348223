import http from "http-common";

const StatutsFicheDeSuiviService = class {
  /**
   * Fonction permettant de récuperer toutes les actions en cours
   * @returns la liste de toutes les actions en cours
   */
  static getAll(params) {
    let url = "/StatutsFicheDeSuivi";
    
    //Gestion de la valeur par défaut de la propriété "isActive" des paramètres
    if (!params)
      return http.get(url, {params: {isActive: true}});
    else if (typeof params === "object" && params.isActive === undefined)
        return http.get(url, {params: {...params, isActive: true}});
    else if (typeof params === "object" && params.isActive !== undefined)
      return http.get(url, {params});
    else
      return http.get(url, {params: {isActive: true}});
  }

  /**
   * Fonction permettant de récupérer une action en cours via son id
   * @param {*} id, id de la action en cours à récupérer
   * @returns la action en cours souhaitée
   */
  static getById(id) {
    return http.get("/StatutsFicheDeSuivi/" + id);
  }
};

export { StatutsFicheDeSuiviService };
