import React from "react";
import {
  ReactTableServerSide,
  IndeterminateCheckbox,
  CellWithRedirection,
} from "_components";

import { RedirectTo, ToLocaleDateString } from "_utils";
import { PropTypes } from "prop-types";
import {
  MaterielService,
  FamilleArticleService,
  PacksService,
  ProduitInterneService,
} from "_services/";
import { Helmet } from "react-helmet";
import { App } from "App";

class BaseMateriel extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Base materiel</title>
        </Helmet>
        <ReactTableServerSide
          title={
            <>
              <div className="d-flex align-self-center nowrap">
                <span>Base Matériel</span>
                {/* <ButtonIcon
                  icon={faCodeBranch}
                  className="btn btn-primary text-light ms-5 me-2"
                  onClick={MaterielService.uploadAndUpdate}
                  textOption=" Mettre à jour les matériels"
                ></ButtonIcon> */}
              </div>
            </>
          }
          service={MaterielService.getAll}
          displayExtractButton={
            App.RightsGuard.current?.hasRight("Materiel", "Extraction") == "RW"
          }
          serviceExtract={MaterielService.Extract}
          module="Materiel"
          redirectToCreate={() => {
            RedirectTo(this.props.history, "/materiels/add");
          }}
          orderByDefault="num_Serie"
          localStorageKeyColumns="BaseMaterielColumns"
          objectFieldToExport={{
            client: "nom_Client",
            contact: "nom_Contact",
            type_Materiel: "designation",
            etat_Avancement: "designation",
          }}
          columns={[
            {
              id: "selection",
              Header: ({ getToggleAllRowsSelectedProps }) => (
                <div className="text-center align-middle" id="selectAll">
                  <IndeterminateCheckbox
                    className="align-middle"
                    {...getToggleAllRowsSelectedProps()}
                  />
                </div>
              ),
              Cell: ({ row }) => (
                <div className="text-center align-middle" id="selectOne">
                  <IndeterminateCheckbox
                    className="align-middle"
                    {...row.getToggleRowSelectedProps()}
                  />
                </div>
              ),
            },
            {
              Header: "Numéro de série",
              accessor: "num_Serie",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "180px",
              type: "text",
              Cell: (row) => {
                return (
                  <CellWithRedirection
                    history={this.props.history}
                    redirection="/materiels/"
                    id={row.cell.row.original.id}
                    value={row.cell.value}
                  />
                );
              },
            },
            {
              Header: "Descriptif",
              accessor: "type_Materiel",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "180px",
              type: "selectSearch",
              optionFieldToDisplay: "designation",
              optionFieldToReturn: "designation",
              valueFieldToDisplay: "designation",
              service: FamilleArticleService.getAll,
              Cell: (row) => {
                return row.cell.value ? (
                  <span>{row.cell.value.designation}</span>
                ) : null;
              },
            },

            {
              Header: "Propriétaire",
              accessor: "proprietaire",
              search: "nom_Client",
              hideable: true,
              exportable: true,
              minWidth: "300px",
              type: "text",
              Cell: (row) => {
                if (row.cell.value) {
                  return (
                    <CellWithRedirection
                      history={this.props.history}
                      redirection="/clients/"
                      id={row.cell.value.id}
                      value={row.cell.value ? row.cell.value.nom_Client : null}
                    />
                  );
                }
                return null;
              },
            },
            {
              Header: "Utilisateur",
              accessor: "utilisateur",
              search: "nom_Utilisateur",
              hideable: true,
              exportable: true,
              minWidth: "300px",
              type: "text",
              Cell: (row) => {
                if (row.cell.value) {
                  return (
                    <CellWithRedirection
                      history={this.props.history}
                      redirection="/clients/"
                      id={row.cell.value.id}
                      value={row.cell.value ? row.cell.value.nom_Client : null}
                    />
                  );
                }
                return null;
              },
            },
            {
              Header: "Produit Interne",
              accessor: "produitInterne",
              hideable: true,
              // sortable: true,
              exportable: true,
              minWidth: "150px",
              type: "selectSearch",
              optionFieldToReturn: "id",
              optionFieldToDisplay: "designation",
              valueFieldToDisplay: "designation",
              service: ProduitInterneService.getAllMat,
              search: "idProduitInterne",
              Cell: (row) => {
                return row.cell.value ? (
                  <span>{row.cell.value.designation}</span>
                ) : null;
              },
            },
            {
              Header: "Pack",
              accessor: "pack",
              search: "pack",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "130px",
              type: "selectSearch",
              optionFieldToReturn: "id",
              optionFieldToDisplay: "designation",
              valueFieldToDisplay: "designation",
              service: PacksService.getAll,

              Cell: (row) => {
                if (row.cell.value) {
                  return <div>{row.cell.value.designation}</div>;
                } else {
                  return null;
                }
              },
            },
            {
              Header: "Date livraison",
              accessor: "date_livraison",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "150px",
              type: "period",
              Cell: (row) => {
                return row.cell.value ? (
                  <span>{ToLocaleDateString(row.cell.value)}</span>
                ) : null;
              },
            },
            {
              Header: "Date fin garantie",
              accessor: "date_Fin_Garantie",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "150px",
              type: "period",
              Cell: (row) => {
                return row.cell.value ? (
                  <span>{ToLocaleDateString(row.cell.value)}</span>
                ) : null;
              },
            },
            {
              Header: "Date fabrication",
              accessor: "date_Fabrication",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "150px",
              type: "period",
              Cell: (row) => {
                return row.cell.value ? (
                  <span>{ToLocaleDateString(row.cell.value)}</span>
                ) : null;
              },
            },
            // {
            //   Header: "Date dernier étalonnage",
            //   accessor: "date_Create",
            //   hideable: true,
            //   sortable: true,
            //   exportable: true,
            //   minWidth: "150px",
            //   type: "period",
            //   Cell: (row) => {
            //     return row.cell.value ? (
            //       <span>{ToLocaleDateString(row.cell.value)}</span>
            //     ) : null;
            //   },
            // },
            {
              Header: "Date rebus",
              accessor: "date_Rebus",
              hideable: true,
              sortable: true,
              exportable: true,
              minWidth: "150px",
              type: "period",
              Cell: (row) => {
                return row.cell.value ? (
                  <span>{ToLocaleDateString(row.cell.value)}</span>
                ) : null;
              },
            },
          ]}
        ></ReactTableServerSide>
      </>
    );
  }
}

BaseMateriel.propTypes = {
  history: PropTypes.object,
};

export { BaseMateriel };
