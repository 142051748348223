import http from "http-common";

const TypePanneFamilleArticleService = class {
  /**
   * Fonction permettant de récuperer tous les TypePanne
   * @returns la liste de tous les TypePanne
   */
  static getAll(params) {
    let url = "/typePanneFamilleArticle";
    
    //Gestion de la valeur par défaut de la propriété "isActive" des paramètres
    if (!params)
      return http.get(url, {params: {isActive: true}});
    else if (typeof params === "object" && params.isActive === undefined)
        return http.get(url, {params: {...params, isActive: true}});
    else if (typeof params === "object" && params.isActive !== undefined)
      return http.get(url, {params});
    else
      return http.get(url, {params: {isActive: true}});
  }
};

export { TypePanneFamilleArticleService };
