import React from "react";
import { PropTypes } from "prop-types";
import { Link } from "react-router-dom";

import "./FicheDeSuivi.css";
import { App } from "App";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faSpinner,
  faFileInvoiceDollar,
  faUserFriends,
  faAddressCard,
  faPrint,
  faTrash,
  faFileContract,
} from "@fortawesome/free-solid-svg-icons";

import { InformationsFicheDeSuivi, Historique, DocumentsFDS } from ".";
import { WarningBar } from "_components";

import { EtatAvancementService, FicheDeSuiviService } from "_services";
import { ButtonIcon, ButtonIconWithValidation, DialogForm } from "_components";
import { Helmet } from "react-helmet";
import { produce } from "immer";
import * as FileSaver from "file-saver";
import { Input } from "_components/Input";
import TabSelector from "_components/FicheComponents/TabSelector";

class FicheDeSuivi extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      tabs: ["Général", "Documents", "Historique"],
      active: "Général",
      editing: false,
      ficheDeSuivi: {},
      openDialogImpressionContratLocSav: null,
      openDialogSelectionEtatFunc: null,
    };

    this.renderActive = this.renderActive.bind(this);
    this.generateTabs = this.generateTabs.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleDelete = this.handleDelete.bind(this);
    this.handleUpdate = this.handleUpdate.bind(this);
    this.handleEditing = this.handleEditing.bind(this);
    this.handleRedirectToDevisCreation =
      this.handleRedirectToDevisCreation.bind(this);
    this.createClient = this.createClient.bind(this);
    this.createContact = this.createContact.bind(this);
    this.setOpenDialogImpressionFunc =
      this.setOpenDialogImpressionFunc.bind(this);
    this.setOpenDialogImpressionFDSFunc =
      this.setOpenDialogImpressionFDSFunc.bind(this);
    this.setOpenDialogSelectionEtatFunc =
      this.setOpenDialogSelectionEtatFunc.bind(this);
  }

  setOpenDialogImpressionFunc(openDialogFunc) {
    this.setState({ openDialogImpressionContratLocSav: openDialogFunc });
  }
  setOpenDialogImpressionFDSFunc(openDialogFunc) {
    this.setState({ openDialogImpressionFDS: openDialogFunc });
  }
  setOpenDialogSelectionEtatFunc(openDialogFunc) {
    this.setState({ openDialogSelectionEtatFunc: openDialogFunc });
  }

  componentDidMount() {
    if (sessionStorage.getItem("ficheDeSuiviOnglet") == null) {
      sessionStorage.setItem("ficheDeSuiviOnglet", this.state.active);
    } else {
      this.setState({ active: sessionStorage.getItem("ficheDeSuiviOnglet") });
    }

    let { id } = this.props.match.params;
    FicheDeSuiviService.getById(id).then((res) => {
      this.setState({
        ficheDeSuivi: {
          ...res.data,
        },
        loading: false,
      });
    });
  }

  /**
   * Permet de mettre a jour l'etat du fiche de suivi sans devoir le recharger
   * @param {*} accessor
   * @param {*} value
   */
  handleChange(accessor, value, afterUpdateFunction = null) {
    if (accessor === "ficheDeSuivi") {
      //Undo
      this.setState({ ficheDeSuivi: value }, afterUpdateFunction);
    } else if (accessor.includes(".")) {
      // Nested property
      var accessorSplit = accessor.split(".");

      if (accessorSplit[0] == "historique") {
        // Format suivi.id.accessor
        var histoId = accessorSplit[1];
        var histoAccessor = accessorSplit[2];

        this.setState(
          produce((prevState) => {
            let histoSuivi = prevState.ficheDeSuivi.historique.find(
              (se) => se.id == histoId
            );
            histoSuivi[histoAccessor] = value;
          }),
          afterUpdateFunction
        );
      } else {
        this.setState(
          (prevState) => ({
            ficheDeSuivi: {
              ...prevState.ficheDeSuivi,
              [accessorSplit[0]]: {
                ...prevState.ficheDeSuivi?.[accessorSplit[0]],
                [accessorSplit[1]]: value,
              },
            },
          }),
          afterUpdateFunction
        );
      }
    } else {
      //Update
      this.setState(
        (prevState) => ({
          ficheDeSuivi: {
            ...prevState.ficheDeSuivi,
            [accessor]: value,
          },
        }),
        afterUpdateFunction
      );
    }
  }

  handleDelete() {
    return FicheDeSuiviService.delete(this.state.ficheDeSuivi).then(() => {
      this.props.history.push({ pathname: "/fichesDeSuivi" });
    });
  }

  handleEditing(editing) {
    this.setState((prevState) => ({
      ...prevState,
      editing: editing,
    }));
  }

  handleUpdate() {
    return FicheDeSuiviService.put(this.state.ficheDeSuivi).then((res) => {
      this.handleChange("ficheDeSuivi", res.data);
    });
  }

  createClient() {
    this.props.history.push({
      pathname: "/clients/add",
    });
  }

  createContact() {
    this.props.history.push({
      pathname: "/contacts/add",
      state: {
        client: this.state.ficheDeSuivi.utilisateur,
      },
    });
  }

  handleRedirectToDevisCreation() {
    this.props.history.push({
      pathname: "/devis/add",
      state: {
        devis: {
          client: this.state.ficheDeSuivi.utilisateur,
          contact_Client: this.state.ficheDeSuivi.contactUtilisateur,
          ficheDeSuivi: { id: this.state.ficheDeSuivi.id },
          charge_Affaire: this.state.ficheDeSuivi.gerant,
        },
      },
    });
  }

  /**
   * Génère les onglets de la fiche
   * @param {*} tabs
   * @returns
   */
  generateTabs(tabs) {
    return (
      <div>
        <TabSelector
          tabs={tabs}
          activeTab={this.state.active}
          onSelect={(tab) => {
            this.setState({ active: tab });
            sessionStorage.setItem("ficheDeSuiviOnglet", tab);
          }}
          notAllowed={this.state.editing}
        ></TabSelector>
        <div className="ms-auto align-self-center">
          <div className="text-end d-flex">
            <div>
              <DialogForm
                tooltip="Imprimer"
                classNameButton="btn btn-success"
                dialogTitle="Impression"
                labelValidateButton="Valider"
                setOpenDialog={this.setOpenDialogImpressionFunc}
                validation={() => {
                  if (this.state.impression != null) return true;
                  else {
                    App.Toaster.current?.createToast({
                      body: "Vous devez sélectionner un modèle",
                      header: "Echec",
                      type: "failure",
                    });

                    return false;
                  }
                }}
                onValidate={() => {
                  FicheDeSuiviService.printContratLoc(
                    this.state.impression.designation,
                    this.state.ficheDeSuivi.id
                  ).then((res) => {
                    var blob = new Blob([res?.data], {
                      type: "	application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    });
                    FileSaver.saveAs(
                      blob,
                      this.state.ficheDeSuivi.reference + ".docx"
                    );
                  });
                }}
                onClose={() => {}}
                body={
                  <div id="PopupCommande">
                    <div>
                      Veuillez choisir un modèle d'impression <br />
                    </div>

                    <Input
                      label="Modèle"
                      accessor="impression"
                      type="selectSearch"
                      value={this.state.impression}
                      valueFieldToDisplay="designation"
                      optionFieldToDisplay="designation"
                      functionAppliedToValue={(value) => {
                        if (value) return value.designation;
                        return null;
                      }}
                      handleChange={(acc, value) =>
                        this.setState({ impression: value })
                      }
                      options={[
                        {
                          id: 0,
                          designation: "Contrat BEL",
                        },
                        {
                          id: 1,
                          designation: "Contrat GRZ",
                        },
                        {
                          id: 2,
                          designation: "Contrat GRZ seul",
                        },
                        {
                          id: 3,
                          designation: "Contrat P4",
                        },
                        {
                          id: 4,
                          designation: "Contrat PLAK",
                        },
                        {
                          id: 5,
                          designation: "Contrat VALISE",
                        },
                      ]}
                      required={true}
                    />
                  </div>
                }
              />
            </div>
            <div>
              <DialogForm
                tooltip="Etat d'avancement"
                classNameButton="btn btn-success"
                dialogTitle="Sélectionner un état d'avancement"
                labelValidateButton="Valider"
                setOpenDialog={this.setOpenDialogSelectionEtatFunc}
                onValidate={() => {
                  FicheDeSuiviService.put(this.state.ficheDeSuivi)
                    .then(() => {
                      this.handleRedirectToDevisCreation();
                    })
                    .catch((erreur) => {
                      console.log(erreur.response);
                      App.Toaster.current?.createToast({
                        body: erreur?.response?.data?.cause,
                        header: "Echec",
                        type: "failure",
                      });
                    });
                }}
                onClose={() => {}}
                body={
                  <div id="PopupCommande">
                    <Input
                      label="Etat d'avancement"
                      accessor="etat_Avancement"
                      type="selectSearch"
                      value={this.state.ficheDeSuivi.etat_Avancement}
                      valueFieldToDisplay="designation"
                      optionFieldToDisplay="designation"
                      functionAppliedToValue={(value) => {
                        if (value) return value.designation;
                        return null;
                      }}
                      handleChange={this.handleChange}
                      service={EtatAvancementService.getAll}
                      required={true}
                    />
                  </div>
                }
              />
            </div>
            <div>
              <DialogForm
                tooltip="Imprimer"
                classNameButton="btn btn-success"
                dialogTitle="Impression"
                labelValidateButton="Valider"
                setOpenDialog={this.setOpenDialogImpressionFDSFunc}
                validation={() => {
                  if (this.state.impressionFDS != null) return true;
                  else {
                    App.Toaster.current?.createToast({
                      body: "Vous devez sélectionner un modèle",
                      header: "Echec",
                      type: "failure",
                    });

                    return false;
                  }
                }}
                onValidate={() => {
                  FicheDeSuiviService.print(
                    this.state.impressionFDS?.designation,
                    this.state.ficheDeSuivi.id
                  ).then((res) => {
                    var blob = new Blob([res?.data], {
                      type: "	application/vnd.openxmlformats-officedocument.wordprocessingml.document",
                    });
                    FileSaver.saveAs(
                      blob,
                      this.state.ficheDeSuivi.reference + ".docx"
                    );
                  });
                }}
                onClose={() => {}}
                body={
                  <div id="PopupCommande">
                    <div>
                      Veuillez choisir un modèle d'impression <br />
                    </div>

                    <Input
                      label="Modèle"
                      accessor="impression"
                      type="selectSearch"
                      value={this.state.impressionFDS}
                      valueFieldToDisplay="designation"
                      optionFieldToDisplay="designation"
                      functionAppliedToValue={(value) => {
                        if (value) return value.designation;
                        return null;
                      }}
                      handleChange={(acc, value) =>
                        this.setState({ impressionFDS: value })
                      }
                      options={[
                        {
                          id: 0,
                          designation: "BEL",
                        },
                        {
                          id: 1,
                          designation: "GRZ",
                        },
                        {
                          id: 3,
                          designation: "P2",
                        },

                        {
                          id: 4,
                          designation: "P4",
                        },
                        {
                          id: 5,
                          designation: "PLAK",
                        },
                        {
                          id: 6,
                          designation: "KDK",
                        },
                      ]}
                      required={true}
                    />
                  </div>
                }
              />
            </div>
          </div>
        </div>
      </div>
    );
  }

  renderActive() {
    switch (this.state.active) {
      case "Général":
        return (
          <InformationsFicheDeSuivi
            ficheDeSuivi={this.state.ficheDeSuivi}
            stateFieldNameToUpdate="ficheDeSuivi"
            handleChange={this.handleChange}
            service={FicheDeSuiviService}
            history={this.props.history}
            editing={this.state.editing}
            handleEditing={this.handleEditing}
            handleUpdate={this.handleUpdate}
          ></InformationsFicheDeSuivi>
        );
      case "Historique":
        return (
          <Historique
            ficheDeSuivi={this.state.ficheDeSuivi}
            stateFieldNameToUpdate="ficheDeSuivi"
            handleChange={this.handleChange}
            service={FicheDeSuiviService}
            history={this.props.history}
            editing={this.state.editing}
            handleEditing={this.handleEditing}
            handleUpdate={this.handleUpdate}
          ></Historique>
        );
      case "Documents":
        return (
          <DocumentsFDS
            ficheDeSuivi={this.state.ficheDeSuivi}
            stateFieldNameToUpdate="ficheDeSuivi"
            handleChange={this.handleChange}
            service={FicheDeSuiviService}
            history={this.props.history}
            editing={this.state.editing}
            handleEditing={this.handleEditing}
            handleUpdate={this.handleUpdate}
          ></DocumentsFDS>
        );
      default:
        return (
          <h4 className="mt-5">Désolé la page n&apos;a pas réussi à charger</h4>
        );
    }
  }

  render() {
    const creationContact = App.RightsGuard?.current?.hasRight(
      "Contact",
      "Creation"
    );

    const isFicheDeSuiviEmpty = !Object.entries(this.state.ficheDeSuivi).length;
    return (
      <>
        <Helmet>
          <title>
            {"Fiche de suivi : ".concat(this.state.ficheDeSuivi.reference)}
          </title>
        </Helmet>
        {this.state.loading ? (
          <div
            style={{
              flexGrow: 1,
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <FontAwesomeIcon icon={faSpinner} size="lg" className="fa-spin" />
          </div>
        ) : (
          <div className="p-3 m-4" id="ficheClient">
            <div className="d-flex overflow-auto justify-content-between py-1">
              <div
                className="d-flex align-items-start nowrap pr-100 ms-2 p"
                style={{ flex: "1", flexWrap: "wrap", alignContent: "center" }}
              >
                <h4 style={{ marginBottom: "0" }}>
                  {"Fiche de suivi : ".concat(
                    this.state.ficheDeSuivi.reference
                  )}
                </h4>
              </div>
              <div className="d-flex">
                <Link
                  to="/fichesDeSuivi"
                  className="btn btn-primary nowrap ms-1"
                >
                  Base Fiche de suivi
                </Link>
                {creationContact == "RW" ? (
                  <ButtonIcon
                    icon={faUserFriends}
                    className="btn btn-success text-light ms-1 boutonInteraction"
                    onClick={this.createContact}
                    tooltip="Créer un nouveau contact"
                  ></ButtonIcon>
                ) : null}
                <ButtonIcon
                  icon={faAddressCard}
                  className="btn btn-success text-light ms-1 boutonInteraction"
                  onClick={this.createClient}
                  tooltip="Créer un nouveau client"
                ></ButtonIcon>
                <ButtonIcon
                  icon={faFileInvoiceDollar}
                  className="btn btn-success text-light ms-1 boutonInteraction"
                  onClick={this.state.openDialogSelectionEtatFunc}
                  tooltip="Créer un nouveau devis à partir de cette fiche de suivi"
                ></ButtonIcon>
                <ButtonIcon
                  icon={faFileContract}
                  className="btn btn-success text-light ms-1 boutonInteraction"
                  onClick={() => {
                    this.state.openDialogImpressionContratLocSav();
                  }}
                  tooltip="Générer un contrat de location"
                ></ButtonIcon>
                <ButtonIcon
                  icon={faPrint}
                  className="btn btn-success text-light ms-1 boutonInteraction"
                  tooltip="Impression fiche de suivis"
                  onClick={() => {
                    this.state.openDialogImpressionFDS();
                  }}
                />
                {App.RightsGuard.current?.hasRight(
                  "Fiche de suivi",
                  "Suppression"
                ) == "RW" ? (
                  <ButtonIconWithValidation
                    icon={faTrash}
                    onClick={this.handleDelete}
                    className="form-control btn btn-danger text-light ms-1 boutonInteraction"
                    alertTitle=" Suppression"
                    alertBody={
                      <div>
                        Souhaitez-vous réellement supprimer ce contact ? <br />
                        (il sera supprimé pour tous ses clients)
                      </div>
                    }
                    alertButtonValidationText="Oui, je veux supprimer."
                  ></ButtonIconWithValidation>
                ) : null}
                {/* <ButtonIconWithValidation
              icon={faTrash}
              onClick={this.handleDelete}
              className="form-control btn btn-danger ms-1"
              alertTitle=" Suppression"
              alertBody="Souhaitez-vous réellement supprimer cette fiche de suivi ?"
              alertButtonValidationText="Oui, je veux supprimer."
            ></ButtonIconWithValidation> */}
                {/* {this.props.history.length > 1 && (
                  <button
                    className="btn btn-primary nowrap ms-1"
                    onClick={() => {
                      this.props.history.goBack();
                    }}
                  >
                    Retour
                  </button>
                )} */}
              </div>
            </div>
            {this.generateTabs(this.state.tabs)}
            <WarningBar
              active={this.state.ficheDeSuivi.actif}
              content={"Non active"}
            />
            <WarningBar
              active={!this.state.ficheDeSuivi.isSousAbonnement}
              content={"Créée sous abonnement"}
            />
            <WarningBar
              active={this.state.ficheDeSuivi.materiel?.pack == null}
              content={
                "Pack : " + this.state.ficheDeSuivi.materiel?.pack?.designation
              }
            />
            <WarningBar
              active={!this.state.ficheDeSuivi.utilisateur?.litige}
              content={"Ce client est en litige"}
            />
            <WarningBar
              active={!this.state.ficheDeSuivi.materiel?.isStolen}
              content={"Ce Materiel a été volé"}
              background={"bg-danger"}
            />
            {!isFicheDeSuiviEmpty && this.renderActive()}
          </div>
        )}
      </>
    );
  }
}

/**
 * Validation des props :
 */
FicheDeSuivi.propTypes = {
  history: PropTypes.object,
  match: PropTypes.object,
};

export { FicheDeSuivi };
