import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactTableServerSide, CellWithRedirection } from "_components";
import {
  ArticleService,
  LicencesService,
  TypesLicenceService,
} from "_services";
import { RedirectToDetails, ToLocaleDateString } from "_utils";
import { PropTypes } from "prop-types";
import React from "react";
import { Helmet } from "react-helmet";
import { NavHashLink as Link } from "react-router-hash-link";

class BaseLicence extends React.PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <>
        <Helmet>
          <title>Base logiciel</title>
        </Helmet>
        <ReactTableServerSide
          title="Base Logiciel"
          module="Licence"
          service={LicencesService.getAll}
          orderByDefault="licenceId"
          orderDefault="DESC"
          localStorageKeyColumns="BaseLicenceColumns"
          columns={[
            {
              Header: "En cours",
              accessor: "active",
              hideable: true,
              sortable: false,
              minWidth: "120px",
              type: "selectSearch",
              optionFieldToReturn: "value",
              optionFieldToDisplay: "display",
              valueFieldToDisplay: "display",
              defaultValue: {
                id: 0,
                display: "Oui",
                value: true,
              },
              options: [
                {
                  id: 0,
                  display: "Oui",
                  value: true,
                },
                {
                  id: 1,
                  display: "Non",
                  value: false,
                },
              ],
              Cell: (row) => {
                if (row.cell.value == true) {
                  return <div>Oui</div>;
                } else {
                  return (
                    <div>
                      Non{" "}
                      <FontAwesomeIcon
                        className="text-danger"
                        icon={faExclamationTriangle}
                      />
                    </div>
                  );
                }
              },
            },
            {
              Header: "Attribuée",
              id: "attribuee",
              accessor: "attribuee",
              hideable: true,
              sortable: false,
              minWidth: "120px",
              type: "selectSearch",
              optionFieldToReturn: "value",
              optionFieldToDisplay: "display",
              valueFieldToDisplay: "display",
              defaultValue: null,
              options: [
                {
                  id: 0,
                  display: "Oui",
                  value: true,
                },
                {
                  id: 1,
                  display: "Non",
                  value: false,
                },
              ],
              Cell: (row) => {
                if (row.cell.value == true) {
                  return <div>Oui</div>;
                } else {
                  return <div>Non</div>;
                }
              },
            },
            {
              Header: "N° Licence",
              accessor: "numero",
              search: "licenceId",
              hideable: true,
              sortable: true,
              minWidth: "130px",
              type: "text",
              Cell: (row) => {
                return (
                  <Link
                    to={
                      "/clients/" +
                      row.cell.row.original.client.id +
                      "#licence" +
                      row.cell.row.original.numero
                    }
                    className="hoverPointer"
                    onClick={() => {
                      sessionStorage.setItem("ficheClientOnglet", "Logiciels");
                      RedirectToDetails(
                        this.props.history,
                        "/clients/",
                        row.cell.row.original.client.id
                      );
                    }}
                  >
                    {row.cell.value}
                  </Link>
                );
              },
            },
            {
              Header: "Catégorie",
              accessor: "classification.categorie",
              hideable: true,
              sortable: true,
              minWidth: "165px",
              type: "selectSearch",
              service: TypesLicenceService.getAllCategories,
              optionFieldToDisplay: "designation",
              optionFieldToReturn: "id",
              valueFieldToDisplay: "designation",
              search: "categorieId",
              defaultValue: null,
              Cell: (row) => {
                return row.cell.value ? (
                  <span>{row.cell.value.designation}</span>
                ) : null;
              },
            },
            {
              Header: "Type",
              accessor: "classification.type",
              hideable: true,
              sortable: true,
              minWidth: "200px",
              type: "selectSearch",
              service: TypesLicenceService.getAllTypes,
              optionFieldToDisplay: "designation",
              optionFieldToReturn: "id",
              valueFieldToDisplay: "designation",
              search: "typeLicenceId",
              defaultValue: null,
              Cell: (row) => {
                return row.cell.value ? (
                  <span>{row.cell.value.designation}</span>
                ) : null;
              },
            },
            {
              Header: "Canal de vente",
              accessor: "classification.canalVente",
              hideable: true,
              sortable: true,
              minWidth: "200px",
              type: "selectSearch",
              service: TypesLicenceService.getAllCanalVente,
              optionFieldToDisplay: "designation",
              optionFieldToReturn: "id",
              valueFieldToDisplay: "designation",
              search: "canalVenteId",
              defaultValue: null,
              Cell: (row) => {
                return row.cell.value ? (
                  <span>{row.cell.value.designation}</span>
                ) : null;
              },
            },
            {
              Header: "Client",
              accessor: "client",
              search: "nom_Client",
              hideable: true,
              sortable: true,
              minWidth: "250px",
              type: "text",
              Cell: (row) => {
                return (
                  <CellWithRedirection
                    history={this.props.history}
                    redirection="/clients/"
                    id={row.cell.row.original.client.id}
                    value={row.cell.value.nom_Client}
                  />
                );
              },
            },
            {
              Header: "Utilisateur",
              id: "user",
              accessor: "user",
              search: "nom_Contact",
              hideable: true,
              sortable: true,
              minWidth: "250px",
              type: "text",
              Cell: (row) => {
                if (
                  row.cell.value &&
                  (row.cell.value.nom_Contact || row.cell.value.prenom_Contact)
                ) {
                  return (
                    <CellWithRedirection
                      history={this.props.history}
                      redirection="/contacts/"
                      id={row.cell.row.original.user.id}
                      value={
                        (row.cell.value.nom_Contact ?? "") +
                        " " +
                        (row.cell.value.prenom_Contact ?? "")
                      }
                    />
                  );
                } else {
                  return null;
                }
              },
            },
            {
              Header: "Modules",
              accessor: "modules",
              hideable: true,
              minWidth: "300px",
              type: "multipleSelectSearch",
              service: ArticleService.getAllModules,
              optionFieldToDisplay: "nom",
              valueFieldToDisplay: "nom",
              optionFieldToReturn: "id",
              defaultValue: null,
              Cell: (row) => {
                return row.cell.value ? (
                  <span>
                    {row.cell.value?.reduce(
                      (acc, licence) => acc + licence.nom + ", ",
                      ""
                    )}
                  </span>
                ) : null;
              },
            },
            {
              Header: "Date de début",
              accessor: "dateDebut",
              hideable: true,
              sortable: true,
              minWidth: "150px",
              type: "period",
              Cell: (row) => {
                return row.cell.value ? (
                  <span>{ToLocaleDateString(row.cell.value)}</span>
                ) : null;
              },
            },
            {
              Header: "Date de fin",
              accessor: "dateFin",
              hideable: true,
              sortable: true,
              minWidth: "150px",
              type: "period",
              Cell: (row) => {
                return row.cell.value ? (
                  <span>{ToLocaleDateString(row.cell.value)}</span>
                ) : null;
              },
            },
          ]}
        />
      </>
    );
  }
}

BaseLicence.propTypes = {
  history: PropTypes.object,
};

export { BaseLicence };
