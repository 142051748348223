import React from "react";

import { ButtonIcon } from "_components";

import { ToLocaleDateString } from "_utils";
import {
  faMinusSquare,
  faPlusSquare,
  faPen,
} from "@fortawesome/free-solid-svg-icons";

import { PropTypes } from "prop-types";

import "./HomePage.css";

class LigneTache extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isAccordionOpen: false,
      callbackForceUpdateContactClient: null,
    };

    this.setForceUpdateContactClient =
      this.setForceUpdateContactClient.bind(this);
    this.setForceUpdateActionTache = this.setForceUpdateActionTache.bind(this);
  }

  setForceUpdateContactClient(callbackFunction) {
    this.setState({
      callbackForceUpdateContactClient: callbackFunction,
    });
  }

  setForceUpdateActionTache(callbackFunction) {
    this.setState({ callbackForceUpdateActionTache: callbackFunction });
  }

  render() {
    return (
      <React.Fragment key={this.props.tache.id}>
        <tr className={this.props.index % 2 ? "shadowLine" : ""}>
          <td
            className={this.props.tache.joursRetard ? "bg-warning" : ""}
            style={{ width: "20px" }}
          >
            <ButtonIcon
              icon={this.state.isAccordionOpen ? faMinusSquare : faPlusSquare}
              className="btn"
              onClick={() =>
                this.setState((prevState) => ({
                  isAccordionOpen: !prevState.isAccordionOpen,
                }))
              }
              style={{ padding: "0rem" }}
              iconSize="sm"
            ></ButtonIcon>
          </td>
          <td style={{ maxWidth: "200px" }}>
            {this.props.tache.statut?.designation ?? "-"}
          </td>
          <td style={{ maxWidth: "250px" }}>
            {ToLocaleDateString(this.props.tache.dateCreation)}
          </td>
          <td>
            {this.props.tache.client ? (
              <>
                <a
                  href={"/clients/" + this.props.tache.client.id}
                  target="_blank"
                  data-text={this.props.tache.client.nom_Client}
                  className="fixWidthHover"
                >
                  {this.props.tache.client.nom_Client ?? "-"}
                </a>
              </>
            ) : (
              "-"
            )}
          </td>
          <td>
            {this.props.tache.contact ? (
              <>
                <a
                  href={"/contacts/" + this.props.tache.contact.id}
                  target="_blank"
                  data-text={
                    this.props.tache.contact.nom_Contact +
                    " " +
                    this.props.tache.contact.prenom_Contact
                  }
                  className="fixWidthHover"
                >
                  {this.props.tache.contact.nom_Contact +
                    " " +
                    this.props.tache.contact.prenom_Contact ?? "-"}
                </a>
              </>
            ) : (
              "-"
            )}
          </td>
          <td>{this.props.tache.action?.designation ?? "-"}</td>
          <td>
            {this.props.tache.typeEntiteReference ? (
              <>
                <a
                  href={
                    "/" +
                    this.props.tache.typeEntiteReference +
                    "/" +
                    this.props.tache.idReference
                  }
                  className="fixWidthHover"
                  target="_blank"
                  data-text={
                    this.props.tache.typeEntiteReference +
                    "/" +
                    this.props.tache.idReference
                  }
                >
                  {this.props.tache.typeEntiteReference +
                    "/" +
                    this.props.tache.idReference}
                </a>
              </>
            ) : (
              "-"
            )}
          </td>
          <td style={{ maxWidth: "120px" }}>
            {this.props.tache.produitInterne?.designation ?? "-"}
          </td>
          <td>{ToLocaleDateString(this.props.tache.dateEcheance) ?? "-"}</td>

          <td style={{ maxWidth: "200px" }}>
            {this.props.tache.assigne
              ? this.props.tache.assigne.nom +
                " " +
                this.props.tache.assigne.prenom
              : "-"}
          </td>
        </tr>
        {this.state.isAccordionOpen ? (
          <>
            <tr className={this.props.index % 2 ? "shadowLine" : ""}>
              <th
                className={this.props.tache.joursRetard ? "bg-warning" : ""}
              ></th>
              <th>Priorité</th>
              <th>Retard</th>
              <th>Famille d'affaire</th>
              <th>Process</th>
              <th>Date de fin</th>
              <th></th>
              <th></th>
              <th></th>
              <th></th>
            </tr>
            <tr className={this.props.index % 2 ? "shadowLine" : ""}>
              <td
                className={this.props.tache.joursRetard ? "bg-warning" : ""}
              ></td>
              <td>{this.props.tache.priorite?.designation ?? "-"}</td>
              <td>
                {this.props.tache.joursRetard
                  ? this.props.tache.joursRetard + "j"
                  : "-"}
              </td>
              <td>{this.props.tache.familleAffaire?.designation ?? "-"}</td>
              <td>{this.props.tache.process?.designation ?? "-"}</td>
              <td>
                {this.props.tache.dateFin != null
                  ? ToLocaleDateString(this.props.tache.dateFin)
                  : "-"}
              </td>
              <td></td>
              <td></td>
              <td></td>
              <td style={{ textAlign: "right" }} className="pe-2">
                <ButtonIcon
                  icon={faPen}
                  iconSize="sm"
                  tooltip="Editer"
                  onClick={() =>
                    this.props?.handleClickEditionTache?.(
                      this.props.board,
                      this.props.tache.id
                    )
                  }
                  className="btn btn-secondary"
                ></ButtonIcon>
              </td>
            </tr>
            <tr className={this.props.index % 2 ? "shadowLine" : ""}>
              <td
                className={this.props.tache.joursRetard ? "bg-warning" : ""}
              ></td>
              <th colSpan={9}>Commentaire</th>
            </tr>
            <tr className={this.props.index % 2 ? "shadowLine" : ""}>
              <td
                className={this.props.tache.joursRetard ? "bg-warning" : ""}
              ></td>
              <td colSpan={9}>
                {this.props.tache.commentaire?.includes("</a>") ? (
                  <span
                    dangerouslySetInnerHTML={{
                      __html: "<div>" + this.props.tache.commentaire + "</div>",
                    }}
                    style={{ whiteSpace: "pre-line" }}
                  />
                ) : (
                  <span style={{ whiteSpace: "pre-line" }}>
                    {this.props.tache.commentaire ?? "-"}
                  </span>
                )}
              </td>
            </tr>
          </>
        ) : null}
      </React.Fragment>
    );
  }
}

LigneTache.propTypes = {
  tache: PropTypes.any,
};

export { LigneTache };
