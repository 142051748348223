import React from "react";
import { PropTypes } from "prop-types";
import { ToLocaleDateString, IfNullToText } from "_utils";
import { App } from "App";

import { BoxInformationsInline } from "_components/FicheComponents/BoxInformationsInline";
import {
  // BoxMapLeaflet,
  AlertDialog,
  WarningBar,
  ButtonIcon,
  ButtonIconWithValidation,
  Box,
} from "_components";
import {
  ClientService,
  ContratCategorieService,
  ContratFinanceurService,
  ContratPeriodiciteService,
  ContratSortieService,
  MaterielService,
  PacksService,
} from "_services";
import { faCheck, faPen, faTrash } from "@fortawesome/free-solid-svg-icons";
import { Input } from "_components/Input";
import { ContratDureeService } from "_services/ContratDureeService";

class General extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      erreur: false,
      edition: false,
    };

    this.renderMateriels = this.renderMateriels.bind(this);
  }

  renderMateriels(right) {
    return (
      <Box
        header={
          <>
            <div className="col-8 align-self-center py-2">
              Liste des matériels
            </div>
            {right == "RW" ? (
              <div className="col-4 text-end p-1 align-self-center">
                <div role="group">
                  <ButtonIcon
                    icon={faCheck}
                    className="btn btn-success btn-sm text-light ms-1"
                    onClick={() => {
                      this.props.handleEditing(false);
                      this.setState({ edition: false });
                      this.props.handleUpdate();
                    }}
                    style={
                      !this.state.edition
                        ? { display: "none" }
                        : { width: "32px" }
                    }
                    tooltip="Arrêter l'édition"
                  ></ButtonIcon>
                  <ButtonIcon
                    icon={faPen}
                    className="btn btn-secondary btn-sm text-light ms-1"
                    onClick={() => {
                      this.props.handleEditing(true);
                      this.setState({ edition: true });
                    }}
                    style={
                      this.state.edition || this.props.editing
                        ? { display: "none" }
                        : { width: "32px" }
                    }
                  ></ButtonIcon>
                </div>
              </div>
            ) : null}
          </>
        }
        body={
          <>
            {this.props.contrat?.materiels?.map((mat, index) => {
              return (
                <div
                  className="d-flex align-items-center justify-content-between mt-2"
                  key={index}
                >
                  <a
                    href={"/materiels/" + mat.id}
                    className="fixWidthHover"
                    target="_blank"
                    data-text={
                      mat.num_Serie + " - " + mat.type_Materiel?.designation
                    }
                  >
                    {mat.num_Serie + " - " + mat.type_Materiel?.designation}
                  </a>
                  <div className="text-end">
                    <ButtonIconWithValidation
                      icon={faTrash}
                      className="btn btn-danger btn-sm text-light ms-1"
                      onClick={() => {
                        this.props.deleteMateriel(mat.id);
                      }}
                      style={
                        right != "RW" || !this.state.edition
                          ? { display: "none" }
                          : { minWidth: "32px" }
                      }
                      alertTitle="Suppression"
                      alertBody="Souhaitez-vous réellement supprimer ce client pour ce contact ?"
                      alertButtonValidationText="Oui, je veux supprimer."
                    ></ButtonIconWithValidation>
                  </div>
                </div>
              );
            })}
            {right == "RO" || !this.state.edition ? (
              <></>
            ) : (
              <div>
                <div>Ajouter un materiel :</div>
                <Input
                  type="selectSearch"
                  accessor="materiels"
                  service={(p) =>
                    MaterielService.getAll({ global: p, size: 20 })
                  }
                  valueFieldToDisplay={[
                    "type_Materiel.designation",
                    "num_Serie",
                  ]}
                  optionFieldToDisplay={[
                    "type_Materiel.designation",
                    "num_Serie",
                  ]}
                  handleChange={this.props.handleChange}
                />
              </div>
            )}
          </>
        }
      />
    );
  }

  render() {
    const right = App.RightsGuard?.current?.hasRight("Contrat", "General");
    if (right == "RW" || right == "RO") {
      let contrat = this.props.contrat;
      const informationsGeneral = [
        {
          value: contrat.client,
          label: "Client",
          accessor: "client",
          type: "selectSearch",
          service: ClientService.getForSelectSearch,
          optionFieldToDisplay: "nom_Client",
          valueFieldToDisplay: "nom_Client",
          showClearButton: false,
          functionAppliedToValue: (value) => (
            <div>
              {value ? (
                <a
                  href={"/clients/" + value.id}
                  target="_blank"
                  data-text={value.nom_Client}
                  className="fixWidthHover"
                >
                  {value.nom_Client}
                </a>
              ) : (
                "-"
              )}
            </div>
          ),
          colSize: 6,
        },
        {
          label: "Actif",
          accessor: "actif",
          type: "checkbox",
          colSize: 6,
          functionAppliedToValue: (value) => {
            return value ? "Oui" : "Non";
          },
        },
        {
          value: contrat.categorie,
          label: "Catégorie",
          accessor: "categorie",
          type: "selectSearch",
          service: ContratCategorieService.getAll,
          optionFieldToDisplay: "designation",
          valueFieldToDisplay: "designation",
          showClearButton: false,
          functionAppliedToValue: (value) => {
            return value ? value.designation : null;
          },
          colSize: 6,
          required: true,
        },
        {
          value: contrat.financeur,
          label: "Financeur",
          accessor: "financeur",
          type: "selectSearch",
          service: ContratFinanceurService.getAll,
          optionFieldToDisplay: "designation",
          valueFieldToDisplay: "designation",
          showClearButton: false,
          functionAppliedToValue: (value) => {
            return value ? value.designation : null;
          },
          colSize: 6,
          required: true,
        },
        {
          value: contrat.pack,
          label: "Pack",
          accessor: "pack",
          type: "selectSearch",
          service: PacksService.getAll,
          optionFieldToDisplay: "designation",
          valueFieldToDisplay: "designation",
          showClearButton: false,
          colSize: 6,
          functionAppliedToValue: (value) => {
            return value ? value.designation : null;
          },
          required: this.props.contrat?.categorie?.designation == "LLD",
        },
        {
          value: contrat.sortie,
          label: "Choix sortie",
          accessor: "sortie",
          type: "selectSearch",
          service: ContratSortieService.getAll,
          optionFieldToDisplay: "designation",
          valueFieldToDisplay: "designation",
          showClearButton: false,
          functionAppliedToValue: (value) => {
            return value ? value.designation : null;
          },
          colSize: 6,
        },
        {
          value: contrat.periodicite,
          label: "Periodicité",
          accessor: "periodicite",
          type: "selectSearch",
          service: ContratPeriodiciteService.getAll,
          optionFieldToDisplay: "designation",
          valueFieldToDisplay: "designation",
          showClearButton: false,
          functionAppliedToValue: (value) => {
            return value ? value.designation : null;
          },
          colSize: 6,
        },
        {
          value: contrat.duree,
          label: "Durée",
          accessor: "duree",
          type: "selectSearch",
          service: ContratDureeService.getAll,
          optionFieldToDisplay: "designation",
          valueFieldToDisplay: "designation",
          showClearButton: false,
          functionAppliedToValue: (value) => {
            return value ? value.designation : null;
          },
          colSize: 6,
        },
        {
          value: contrat.numEtude,
          label: "N° Etude",
          accessor: "numEtude",
          type: "text",
          colSize: 6,
          required: true,
        },
        {
          label: "Immo",
          accessor: "immo",
          type: "text",
          colSize: 6,
        },

        {
          label: "Sortie Immo",
          accessor: "sortieImmo",
          type: "checkbox",
          colSize: 6,
          functionAppliedToValue: (value) => {
            return value ? "Oui" : "Non";
          },
        },
        {
          label: "RIB",
          accessor: "rib",
          type: "checkbox",
          colSize: 6,
          functionAppliedToValue: (value) => {
            return value ? "Oui" : "Non";
          },
        },

        {
          label: "Abonnement Sage",
          accessor: "abonnementSage",
          type: "checkbox",
          colSize: 12,
          functionAppliedToValue: (value) => {
            return value ? "Oui" : "Non";
          },
        },

        {
          label: "Contrat Service envoyé le ",
          accessor: "contratServiceEnvoye",
          type: "date",
          colSize: 6,
          functionAppliedToValue: (value) => {
            return value ? (
              <div>{ToLocaleDateString(value)}</div>
            ) : (
              <div>{"-"}</div>
            );
          },
        },
        {
          label: "Contrat Service retour le ",
          accessor: "contratServiceRetour",
          type: "date",
          colSize: 6,
          functionAppliedToValue: (value) => {
            return value ? (
              <div>{ToLocaleDateString(value)}</div>
            ) : (
              <div>{"-"}</div>
            );
          },
        },
        {
          label: "Contrat exterieur envoyé le ",
          accessor: "contratExtEnvoye",
          type: "date",
          colSize: 6,
          functionAppliedToValue: (value) => {
            return value ? (
              <div>{ToLocaleDateString(value)}</div>
            ) : (
              <div>{"-"}</div>
            );
          },
        },
        {
          label: "Contrat exterieur retour le ",
          accessor: "contratExtRet",
          type: "date",
          colSize: 6,
          functionAppliedToValue: (value) => {
            return value ? (
              <div>{ToLocaleDateString(value)}</div>
            ) : (
              <div>{"-"}</div>
            );
          },
        },

        {
          label: "PV livraison envoyé le ",
          accessor: "pvLivraisonEnvoye",
          type: "date",
          colSize: 6,
          required:
            (contrat?.categorie?.designation == "ASS" &&
              contrat?.financeur?.designation != "Sol Solution") ||
            contrat?.financeur?.designation != "Sol Solution",
          functionAppliedToValue: (value) => {
            return value ? (
              <div>{ToLocaleDateString(value)}</div>
            ) : (
              <div>{"-"}</div>
            );
          },
        },
        {
          label: "Pv livraison retour le ",
          accessor: "pvLivraisonRetour",
          type: "date",
          colSize: 6,
          required:
            (contrat?.categorie?.designation == "ASS" &&
              contrat?.financeur?.designation != "Sol Solution") ||
            contrat?.financeur?.designation != "Sol Solution",
          functionAppliedToValue: (value) => {
            return value ? (
              <div>{ToLocaleDateString(value)}</div>
            ) : (
              <div>{"-"}</div>
            );
          },
        },
        {
          label: "Début abonnement ",
          accessor: "debutAbonnement",
          type: "date",
          functionAppliedToValue: (value) => {
            return value ? (
              <div>{ToLocaleDateString(value)}</div>
            ) : (
              <div>{"-"}</div>
            );
          },
          colSize: 6,
        },
        {
          label: "Fin abonnement ",
          accessor: "finAbonnement",
          type: "date",
          functionAppliedToValue: (value) => {
            return value ? (
              <div>{ToLocaleDateString(value)}</div>
            ) : (
              <div>{"-"}</div>
            );
          },
          colSize: 6,
        },
        {
          label: "Montant HT de l'abonnement ",
          accessor: "montantAbonnementHT",
          type: "decimal",
          colSize: 6,
          handleBlur: this.props.handleChange,
        },
        {
          label: "Montant HT périodique de l'abonnement",
          accessor: "montantAbonnementPeriodiqueHT",
          type: "decimal",
          colSize: 6,
          handleBlur: this.props.handleChange,
        },
        {
          label: "Commentaire",
          accessor: "commentaire",
          type: "textArea",
          colSize: 12,
        },
      ];

      const informationsFiche = [
        {
          label: "Date de création",
          accessor: "date_Create",
          functionAppliedToValue: (value) => {
            return value
              ? ToLocaleDateString(contrat.date_Create) +
                  " par " +
                  IfNullToText(contrat.user_Create?.nom, "inconnu") +
                  " " +
                  IfNullToText(contrat.user_Create?.prenom, "")
              : "-";
          },
          colSize: 6,
        },
        {
          label: "Date de modification",
          accessor: "date_Modif",
          functionAppliedToValue: (value) => {
            return value
              ? ToLocaleDateString(contrat.date_Modif) +
                  " par " +
                  IfNullToText(contrat.user_Modif?.nom, "inconnu") +
                  " " +
                  IfNullToText(contrat.user_Modif?.prenom, "")
              : "-";
          },
          colSize: 6,
        },
      ];

      return (
        <>
          <AlertDialog
            title="Erreur"
            body="Vous ne pouvez pas supprimer le contrat."
            valider="Ok"
            open={this.state.erreur}
            handleClose={() => {
              this.setState({ erreur: false });
            }}
          />
          <div className="row">
            <div className="col-12">{this.renderMateriels(right)}</div>
            <div className="col-12">
              <BoxInformationsInline
                title="Informations sur le contrat"
                datas={informationsGeneral}
                state={this.props.contrat}
                stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                service={this.props.service}
                handleChange={this.props.handleChange}
                handleEditing={this.props.handleEditing}
                editing={this.props.editing}
                colSize="4"
                NoEdition={right != "RW"}
              />
            </div>
            <div className="col-12">
              <BoxInformationsInline
                title="Informations sur la fiche"
                datas={informationsFiche}
                state={this.props.contrat}
                stateFieldNameToUpdate={this.props.stateFieldNameToUpdate}
                service={this.props.service}
                handleChange={this.props.handleChange}
                colSize="4"
                boxSize="small"
                NoEdition
              />
            </div>
          </div>
        </>
      );
    } else {
      return (
        <>
          <WarningBar
            active={false}
            content={"Vous n'avez pas le droit de voir cette page"}
          />
        </>
      );
    }
  }
}

General.propTypes = {
  contrat: PropTypes.object,
  stateFieldNameToUpdate: PropTypes.string,
  handleChange: PropTypes.func,
  history: PropTypes.object,
  service: PropTypes.func,
};

export { General };
